import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import { TextInput } from './styles'

const Input = ({
  className, src, alt, type, name, placeholder,
}) => (
  <TextInput className={className}>
    <div className="icon"><img src={src} alt={alt} /></div>
    <Field type={type} name={name} placeholder={placeholder} />
  </TextInput>
)

Input.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
}

Input.defaultProps = {
  className: '',
  alt: 'icon',
  type: 'text',
  placeholder: 'placeholder',
}

export default Input
