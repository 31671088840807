import styled from 'styled-components'

export const Button = styled.button`
  background: #AE6AEF;
  border-radius: 4px;
  width: 63px;
  height: 58px;
  display: flex;

  > img {
    width: fit-content;
    margin: auto;
  }
`;
