import React from 'react'
import PropTypes from 'prop-types'
import bg from '../../assets/doctor_body.png'
import docImg from '../../assets/doctor_and_app.svg'

import {
  Container, BgImg, Content, Illustration, Wrapper,
  Logo, FormContainer,
} from './styles'

const Login = ({ children }) => (
  <Container>
    <div className="bg" />
    <BgImg>
      <img src={bg} alt="bg_img" />
      <div />
      <div />
    </BgImg>
    <Content>
      <Wrapper>
        <Logo />
        <FormContainer>
          {children}
        </FormContainer>
      </Wrapper>
    </Content>
    <Illustration src={docImg} alt="illustration_adri" />
  </Container>
)

Login.propTypes = {
  children: PropTypes.elementType.isRequired,
}

export default Login
