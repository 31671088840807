import styled from 'styled-components'
import { ReactComponent as logoSvg } from '../../assets/adri_logo.svg'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background: linear-gradient(to right, #760BFF 1.43%, #9507D8 98.7%);
  /* TEMP */
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h1 {
    color: #FFF;
  }
`;

export const Logo = styled(logoSvg)``;
