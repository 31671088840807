import React from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { Form } from './styles'
import TextInput from '../../components/text-input'
import PaperPlane from '../../assets/paper_plane.svg'
import Mail from '../../assets/mail.svg'
import Button from '../../components/button'


const ForgotPass = ({ history }) => (
  <>
    <h1>Esqueci minha senha</h1>
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values, actions) => {
        alert(JSON.stringify(values, null, 2));
        history.push('/senha-enviada')
      }}
    >
      {() => (
        <Form>
          <TextInput
            src={Mail}
            type="email"
            name="email"
            placeholder="Email"
          />

          <div className="flex justify-end align-end">
            <Button type="submit">
              <img src={PaperPlane} alt="" />
            </Button>
          </div>

        </Form>
      )}
    </Formik>
  </>
)

ForgotPass.propTypes = {
  history: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default ForgotPass
