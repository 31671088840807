import styled from 'styled-components'
import { ReactComponent as logoSvg } from '../../assets/adri_logo.svg'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background: linear-gradient(to right, #760BFF 1.43%, #9507D8 98.7%);
`;

export const BgImg = styled.div`
  width: 42%;
  overflow: hidden;
  position: relative;

  > img {
    min-height: 100%;
    min-width: 100%;
  }

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:first-of-type {
      background: #5D0BAB;
      opacity: 0.5;
    }
    &:last-child {
      background: #5448FF;
      opacity: 0.5;
    }
  }


  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.div`
  width: 58%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 70px;
  width: 50%;
`;

export const Logo = styled(logoSvg)``;

export const Illustration = styled.img`
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  left: 34%;
  width: 45%;
  z-index: 2;
`;

export const FormContainer = styled.div`
  background: linear-gradient(180deg, #5D0BAB 0%, #5D0BAB 100%);
  border-radius: 8px;
  padding: 18px 50px 32px;
  display: grid;
  row-gap: 20px;
  width: 100%;
  position: relative;

  h1 {
    color: #FFF;
    font-style: normal;
    font-weight: normal;
    text-align: center;
  }

  > a, a:any-link{
    color: #FFF;
    font-size: 12px;
  }
`;
