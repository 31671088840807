import styled from 'styled-components';

export const Container = styled.div`
  min-height: 200px;
  width: 80%;
  justify-self: center;
  justify-content: center;
  display: flex;
  flex-flow: column;
  color: #FFF;
  text-align: center;
  line-height: 20px;

  > img {
    margin-bottom: 15px;
  }

  > a {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
`;
