/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Field, Formik } from 'formik'
import AppContext from '../../context'
import {
  Form, CheckboxWrapper, Cadastrar,
} from './styles'
// components
import Button from '../../components/button'
import TextInput from '../../components/text-input'
// icons
import Credential from '../../assets/credential.svg'
import Lock from '../../assets/lock.svg'
import PaperPlane from '../../assets/paper_plane.svg'

const Login = () => {
  const context = useContext(AppContext);

  return (
    <>
      <h1>Login</h1>
      <Formik
        initialValues={{ email: '', pass: '', keepLoged: false }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            actions.setSubmitting(false)
            context.setSigned(true)
          }, 1000);
        }}
      >
        {() => (
          <Form>
            <TextInput
              src={Credential}
              type="email"
              name="email"
              placeholder="Email"
            />

            <TextInput
              src={Lock}
              type="password"
              name="pass"
              placeholder="Senha"
            />

            <div className="flex space-between align-end">
              <CheckboxWrapper>
                <Field type="checkbox" name="keepLoged" id="keep_logged" />
                <label htmlFor="keep_logged">Manter conectado</label>
                <div id="checkbox_border" />
              </CheckboxWrapper>

              <Button type="submit">
                <img src={PaperPlane} alt="" />
              </Button>
            </div>

            <div className="flex">
              <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
            </div>

          </Form>
        )}
      </Formik>

      <Cadastrar>
        Não possui cadastro?
        {' '}
        <Link to="/">Cadastrar</Link>
      </Cadastrar>
    </>
  )
}

export default Login
