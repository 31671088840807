import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  display: grid;
  row-gap: 18px;
`;

export const TextInput = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 25px;
  background: #5D0BAB;
  box-shadow: -6px -6px 12px #6B19BA, 6px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  .icon {
    background: #AE6AEF;
    border-radius: 4px;
    width: 63px;
    height: 58px;
    display: flex;

    > img {
      width: fit-content;
      margin: auto;
    }
  }

  > input {
    background: transparent;
    font-size: 16px;
    line-height: 20px;
    height: 20px;
    color: #fff;

    &::placeholder{
      color: #fff;
    }
  }
`;
