import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  display: grid;
  row-gap: 18px;
`;

export const TextInput = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 25px;
  background: #5D0BAB;
  box-shadow: -6px -6px 12px #6B19BA, 6px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  .icon {
    background: #AE6AEF;
    border-radius: 4px;
    width: 63px;
    height: 58px;
    display: flex;

    > img {
      width: fit-content;
      margin: auto;
    }
  }

  > input {
    background: transparent;
    font-size: 16px;
    line-height: 20px;
    height: 20px;
    color: #fff;

    &::placeholder{
      color: #fff;
    }
  }
`;

export const CheckboxWrapper = styled.div`

  display: flex;
  align-items: center;
  position: relative;

  > label {
    margin-left: 22px;
    color: #FFF;
    font-size: 12px;
    position: relative;

    ::before{
      position: absolute;
      content: "";
      display: inline-block;
      width: 21px;
      height: 20px;
      top: -4px;
      left: -35px;
      border-radius: 4px;
      background: #5D0BAB;
      box-shadow: -4px -4px 4px rgba(187, 125, 245, 0.22), 4px 4px 4px rgba(0, 0, 0, 0.22);
    }

    ::after {
      position: absolute;
      content: "";
      display: inline-block;
      height: 6px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      left: -30px;
      top: 0px;
      transform: rotate(-45deg);
    }
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }
  /*Unhide the checkmark on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  input[type="checkbox"] {
    opacity: 0;
  }

  #checkbox_border {
    position: absolute;
    left: 2px;
    top: -2px;
    width: 17px;
    height: 16px;
    border: 1px solid #3B0073;
    border-radius: 4px;
    pointer-events: none;
  }
`;

export const Cadastrar = styled.div`
  position: absolute;
  bottom: -30px;
  right: 0;
  color: #FFF;
  font-size: 12px;

  > a, a:visited {
    color: #FFF;
    font-weight: bold;
  }
`;
